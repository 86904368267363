import _shebangRegex from "shebang-regex";
var exports = {};
const shebangRegex = _shebangRegex;

exports = (string = "") => {
  const match = string.match(shebangRegex);

  if (!match) {
    return null;
  }

  const [path, argument] = match[0].replace(/#! ?/, "").split(" ");
  const binary = path.split("/").pop();

  if (binary === "env") {
    return argument;
  }

  return argument ? `${binary} ${argument}` : binary;
};

export default exports;